.about {
  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}

.about-heading {
  font-size: 3rem;
  position: relative;
}
.about-heading::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: -3rem;
  height: 2px;
  width: 20rem;
  background-color: yellow;
}
.about-image {
  height: 25rem;
  width: auto;
  border-radius: 50%;
  box-shadow: 0 10px 10px 2px yellow;
  object-fit: cover;
}
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  background-color: rgba(48, 47, 47, 0.4);
  padding: 1rem;
  border-radius: 2rem;
}
.left-container {
  padding: 0 2rem;
}
.right-content {
  font-size: 1.2rem;
  line-height: 2.5rem;
  max-width: 45rem;
  padding: 0 2rem;
}

@media screen and (max-width: 600px) {
  .about-container {
    padding: 2rem 0;
  }
  .about-image {
    height: 20rem;
  }
}
