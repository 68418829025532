#hero {
  /* height: 90vh; */
  width: 100vw;
  /* max-width: 85rem; */

  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 3rem;
}

.heroContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 700;
  width: 50%;
  gap: 2rem;
}
.introName {
  color: yellow;
}
.introPara {
  font-size: medium;
  font-weight: 200;
}

.bg {
  object-fit: contain;
  height: 40rem;
  border-radius: 50%;
  transition: 0.5s ease;
}
.bg:hover {
  transform: scale(0.9);
  box-shadow: -5px 0 0 2px yellow;
}

.btn {
  background-color: white;
  margin: 1rem 0;
  padding: 0.75rem 2rem;
  border-radius: 2rem;
  border: none;
  font-size: 1rem;
  display: flex;
  gap: 0.4rem;
}

@media screen and (max-width: 600px) {
  .heroContent {
    width: 100%;
    padding: 0 auto;
    text-align: center;
    height: 85vh;
    padding-top: -4rem;
  }
  .portfolio-image {
    display: none;
  }
  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
