.navbar {
  height: 5rem;
  background-color: rgba(48, 47, 47, 0.4);
  width: 100%;
  /* max-width: 85rem; */
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
}
.logo {
  object-fit: cover;
  font-size: 3rem;
  text-align: center;
  height: 3rem;
  width: 4rem;
  display: flex;
  gap: 0.2rem;
  color: rgb(255, 255, 0);
  /* color: rgb(227, 80, 11); */
}
.logo span {
  font-size: 2rem;
  display: flex;
  align-items: end;
}
.desktopMenu {
  display: flex;
  flex-direction: row;
}
.desktopMenu li,
.navMenu {
  list-style: none;
}
.desktopMenuListItem,
.listItem {
  cursor: pointer;
  margin: 1rem;
  font-size: 1.2rem;
  transition: 0.2s linear;
  color: white;
}
.desktopMenuListItem:hover,
.listItem:hover {
  color: yellow;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid yellow;
}
.desktopMenuButton {
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  border: none;
  background-color: white;
  color: black;
  border-radius: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.desktopMenuButton span {
  font-size: 0.9rem;
}
.desktopMenuBtn {
  display: flex;
  gap: 1rem;
}
.lightDarkMode {
  font-size: 1rem;
  border: none;
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-top: 0.2rem;
}
.react-icons {
  display: none;
}

/* mobile */
.navMenu {
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background-color: rgb(40, 40, 40);
  border-radius: 1rem;
  gap: 1rem;
}
.mobMenu {
  font-size: 2rem;
  display: none;
}
.navMenu li {
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 0.2rem;
  background-color: rgb(30, 30, 30);
}
.listItem {
  padding: 0 5rem;
}
.contactLink {
  text-decoration: none;
  color: black;
  transition: 0.5s ease;
}
.contactLink:hover {
  background-color: gold;
  transform: scale(1.02);
}

/* media query */

@media screen and (max-width: 600px) {
  .mobMenu {
    display: flex;
  }

  .navMenu {
    /* background: linear-gradient(rgb(51, 51, 51), rgb(55, 55, 55)); */
    margin-top: 2rem;
    font-size: 2.5rem;
  }

  .desktopMenu {
    display: none;
  }
  .contactLink {
    display: none;
  }
  .lightDarkMode {
    margin-left: 10rem;
  }
}
