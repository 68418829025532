.myProjects {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(68, 68, 66, 0.7); */
  /* background: rgb(80, 81, 81); */
}
.myProjects h2 {
  font-size: 3rem;
  margin-bottom: 4rem;
}
.myProject-title {
  position: relative;
}
.myProject-title:before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: -3rem;
  height: 2px;
  width: 20rem;
  background-color: yellow;
}
.project-container {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 2rem;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  /* padding: 0 4rem; */
}
.project-box {
  position: relative;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem rgb(36, 36, 35);
  overflow: hidden;
  /* display: flex; */
  width: 350px;
  height: 250px;
}
.project-box img {
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
}
.project-box:hover img {
  transform: scale(1.1);
}

.project-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgb(255, 215, 0));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transform: translateY(100%);
  transition: 0.6s ease;
  opacity: 0;
}
.project-box:hover .project-content {
  transform: translateY(0);
  opacity: 1;
}

.project-content h4 {
  font-size: 2.5rem;
}
.project-content p {
  padding: 0 2rem;
}

.project-content a {
  display: inline-flex;
  justify-content: center;
  font-size: 1rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  padding: 0.5rem;
  border: 2px solid black;
}
