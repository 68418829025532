.contact {
  padding-top: 5rem; /* background-color: rgb(86, 84, 81); */
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
hr {
  width: 60%;
  border: 1px solid gold;
}
.contact-box {
  display: flex;
  flex-wrap: wrap;
}
.contact-left {
  flex-basis: 40%;
}
.contact-right {
  flex-basis: 60%;
}
.contact-left h2 {
  font-size: 3rem;
}
.contact-left p {
  margin-top: 3rem;
  font-size: 1.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.contact-left span {
  font-size: 2rem;
  color: red;
}
.social-icon a {
  font-size: 2.5rem;
  text-decoration: none;
  display: inline-block;
  margin: 1rem 1rem 0 0;
  transition: 0.5s;
  color: white;
}

.social-icon a:hover {
  color: gold;
  transform: translateY(-5px);
}

.contact-right form {
  width: 100%;
}
form .btn2 {
  background-color: #ffd700;
  display: inline-block;
  border-radius: 5px;
}
form input,
form textarea {
  width: 100%;
  border: 0;
  outline: none;
  background: #262626;
  padding: 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  color: white;
  border-radius: 5px;
}
form textarea {
  resize: none;
}

@media screen and (max-width: 600px) {
  .contact-box {
    display: inline-block;
    overflow: hidden;
    min-width: 20rem;
  }
  .contact-left {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }
}
