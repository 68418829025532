.skills {
  /* height: 90vh; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}
.skillTitle {
  font-size: 3rem;
  font-weight: 500;
  position: relative;
}

.skillTitle::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: -3rem;
  height: 2px;
  width: 20rem;
  background-color: yellow;
}

.skillDesc {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 60rem;
  line-height: 2rem;
  word-spacing: 0.2rem;
}
.skillLists {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.skillBars {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background: rgb(45, 44, 44, 0.5);
  flex-wrap: wrap;
}
.img {
  font-size: 4rem;
}
.fa-html5 {
  color: #ff5733;
}
.fa-css3-alt {
  color: skyblue;
}
.fa-js {
  color: yellow;
}
.fa-react {
  color: #04d8f9;
}
.fa-bootstrap {
  color: #553c7b;
}
