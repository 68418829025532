* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  background: transparent;
}
body {
  background-color: rgb(30, 30, 30);
  /* background-color: rgb(187, 189, 190); */
  color: white;
  /* color: black; */

  overflow: hidden;
  overflow-y: scroll;

  font-family: "Roboto", sans-serif;
}
.dark-theme {
  background-color: rgb(30, 30, 30);
  color: white;
}
.light-theme {
  /* background: linear-gradient(60deg, #e0d2c7, #739d96); */
  background: linear-gradient(90deg, #dadab6, #e0d6c8, rgb(119, 106, 71));

  color: black;
}
